import villa from "../Assets/INDIVIDUAL_VILLA.jpg";
export const services = [
  {
    img: "https://image3.jdomni.in/banner/10072020/5C/C8/4C/B7F2BFF9F16D47195915FCA38B_1594370356816.png?output-format=webp",
    title: "Construction Contractors",
    description:
      "We excel in providing end to end solutions for all the construction complications of our clients.",
  },
  {
    img: "https://image1.jdomni.in/banner/10072020/F6/A4/5F/D0586029EDE09EDE7D4E44C555_1594370391291.png?output-format=webp",
    title: "Building & Developing",
    description:
      "We offer Building Construction services as per your specifications within the suitable time and with the priority to safety and quality",
  },
  {
    img: villa,
    title: " Individual villa",
    description:
      "We build individual Villas directly from clients from planning to Key handover.",
  },
  {
    img: "https://image2.jdomni.in/banner/18042018/77/9E/10/99A929B9AEF2BB7EFD8B21C0F0_1524029612192.jpg?output-format=webp",
    title: "Turnkey Project Work",
    description:
      " Our team has the most qualified civil engineers and other dignified experts, providing the best turnkey project services to you.",
  },
  {
    img: "https://image3.jdomni.in/banner/18042018/6B/A0/A2/A6568592302139EBDA4B866B9C_1524029327035.jpg?output-format=webp",
    title: " Interior Works",
    description:
      "As per the taste, preference and budget of our esteemed clients we help them in organising their space the way they want.",
  },
  {
    img: "https://image2.jdomni.in/banner/10072020/38/9B/0F/65E62A2249EE23486F1B4FAADE_1594370442207.jpg?output-format=webp",
    title: " Building Rennovation",
    description:
      "Our company undertakes projects related to building renovation and all other affiliated services.",
  },
];

// function isInViewport(element) {
//   const rect = element.getBoundingClientRect();
//   return (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <=
//       (window.innerHeight || document.documentElement.clientHeight) &&
//     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//   );
// }

// export function fadeInWhenVisible() {
//   const fadeElements = document.querySelectorAll(".fade-in-text");

//   fadeElements.forEach((element) => {
//     if (isInViewport(element)) {
//       element.classList.add("fade-in");
//     } else {
//       element.classList.remove("fade-in");
//     }
//   });
// }

export function fadeInWhenVisible(timeoutId) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        timeoutId = setTimeout(() => {
          entry.target.classList.add("fade-in");
        }, 200);
      } else {
        clearTimeout(timeoutId);
        entry.target.classList.remove("fade-in");
      }
    });
  });
  return observer;
}
