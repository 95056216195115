import React from "react";
import "../Styles/Contacts.css";
import ContactDetails from "./ContactDetails";
import FeedbackForm from "./FeedbackForm";

const Contacts = () => {
  return (
    <section className="contacts-content" id="contacts">
      <div className="contact-content-container ">
        <div>
          <h1>Contact Us</h1>
          <ContactDetails />
          <FeedbackForm />
        </div>
      </div>
    </section>
  );
};

export default Contacts;
