import React from "react";
import "../Styles/testimonials.css";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { testimonials } from "../Utils/Testimonials";

const Testimonials = () => {
  const carouselStyles = {
    minHeight: "50vh",
  };
  return (
    <div className="testimonials-content" id="testimonials">
      <div className="testimonials-content-container">
        <h1>Testimonials</h1>
        <Carousel
          autoPlay={true}
          interval={1700}
          infiniteLoop={true}
          showStatus={false}
          showArrows={false}
          showThumbs={false}
          style={carouselStyles}
        >
          {testimonials.map(({ description, name }) => (
            <div className="testimonials-container" key="name">
              <div>{description}</div>
              <div className="testimonials-name">-- {name}</div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
