import React, { useRef, useState } from "react";
import FormInput from "./FormInput";
import { checkValidInput, formPlaceHolder } from "../Utils/contacts";
import { Modal, Button } from "@mui/material";
import axios from "axios";
import emailjs from "@emailjs/browser";
// cccreationinteriors@gmail.com

const FeedbackForm = () => {
  const [input, setInput] = useState({
    Name: "",
    Mobile: "",
    Email: "",
    Message: "",
  });

  const [errors, setErrors] = useState({
    Name: "",
    Mobile: "",
    Email: "",
  });

  const [openModal, setOpenModal] = useState(false);

  const form = useRef();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!input.Name || !input.Mobile || !input.Email) {
      alert("Please fill in all fields.");
      return;
    }

    emailjs
      .sendForm("service_l80k15b", "template_jorfjip", form.current, {
        publicKey: "ZOcGfFt3ZK37eqLt9",
      })
      .then(
        () => {
          setOpenModal(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    setInput({
      Name: "",
      Mobile: "",
      Email: "",
      Message: "",
    });
  };
  // const fetchFeedBackResponse = async () => {
  //   try {
  //     const response = await axios.post("/feedback", input);
  //     setInput({
  //       Name: "",
  //       Mobile: "",
  //       Email: "",
  //       Message: "",
  //     });
  //     setOpenModal(true);
  //     console.log(response.data);
  //   } catch (error) {
  //     alert(error.response.status + "! Internal server issue");
  //   }
  // };
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   setErrors({
  //     Name: "",
  //     Mobile: "",
  //     Email: "",
  //   });

  //   const result = checkValidInput(input);
  //   if (!result.isValid) {
  //     setErrors(result.newErrors);
  //     return;
  //   }

  //   fetchFeedBackResponse();
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <form ref={form} autoComplete="off" onSubmit={handleSubmit}>
        <div className="fb-container">
          <div className="fb-details">
            {formPlaceHolder.map((Placevalue) => (
              <div className="form-inp" key={Placevalue.name}>
                <FormInput
                  name={Placevalue.name}
                  className="fb-input"
                  handleChange={handleChange}
                  value={input[Placevalue.name]}
                  type={Placevalue.type}
                  placeholder={Placevalue.placeholder}
                />
                <div style={{ color: "red" }}>{errors[Placevalue.name]}</div>
              </div>
            ))}
          </div>
          <div className="message-textarea">
            <textarea
              name="Message"
              className="fb-input"
              value={input["Message"]}
              placeholder="Message"
              type="text"
              autoComplete="new-password"
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <button className="fb-sbt-btn">Submit</button>
        </div>
      </form>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className="modal-content">
          <h2 className="modal-heading">Thank you for reaching out!</h2>
          <p className="modal-text">
            We appreciate your feedback. We will contact you shortly.
          </p>
          <Button className="modal-button" onClick={() => setOpenModal(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FeedbackForm;
