import React, { useState } from "react";

const FormInput = ({
  name,
  value,
  className,
  placeholder,
  type,
  handleChange,
}) => {
  return (
    <input
      className={className}
      name={name}
      value={value}
      placeholder={placeholder}
      type={type}
      autoComplete="new-password"
      onChange={handleChange}
    />
  );
};

export default FormInput;
