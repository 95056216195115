import React from "react";
import { contactDetails } from "../Utils/contacts";
import EachElement from "../Hooks/EachElement";

const ContactDetails = () => {
  return (
    <div className="contact-detail-container">
      <div className="contact-detail-card">
        <EachElement
          of={contactDetails}
          render={(contact) => (
            <div className="contact-detail-card-val" key={contact.title}>
              <img src={contact.img} alt="" />
              <h3>{contact.title}</h3>
              <p>{contact.description}</p>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default ContactDetails;
