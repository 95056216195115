import React from "react";
import "../Styles/Clients.css";
import { clientImages } from "../Utils/clients";
import EachElement from "../Hooks/EachElement";

const Clients = () => {
  return (
    <div className="clients-content" id="clients">
      <div className="clients-content-container">
        <h1>Our Esteemed Clients</h1>
        <div className="clients-image-container">
          <EachElement
            of={clientImages}
            render={(img, index) => <img src={img} alt="" key={index} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Clients;
