import React from "react";
import "../Styles/Services.css";
import ServiceBlock from "./ServiceBlock";
import { services } from "../Utils/ServiceBlock";
import EachElement from "../Hooks/EachElement";
const Services = () => {
  return (
    <div className="services-content" id="services">
      <h1>Services Offered</h1>
      <div className="services-content-container">
        <EachElement
          of={services}
          render={(item, index) => <ServiceBlock service={item} key={index} />}
        />
      </div>
    </div>
  );
};

export default Services;
