import location from "../Assets/location.png";
import mail from "../Assets/mail.png";
import call from "../Assets/call.png";
import time from "../Assets/time.png";
export const contactDetails = [
  {
    img: location,
    title: "Our Office Address",
    description:
      "Nagamangala,  Embassy springs, Devanahalli taluk, Bengaluru rural district - 562110",
  },
  {
    img: mail,
    title: "General Enquiries",
    description: "suhas7150@gmail.com",
  },
  {
    img: call,
    title: "Call Us",
    description: "+91-919945207882",
  },
  {
    img: time,
    title: "Our Timings",
    description: "Mon - Sun : 09:00 AM - 09:00 PM.",
  },
];

export const formPlaceHolder = [
  { name: "Name", placeholder: "First Name", type: "text" },
  { name: "Mobile", placeholder: "Mobile Number", type: "tel" },
  { name: "Email", placeholder: "Email ID", type: "email" },
];

export const checkValidInput = (input) => {
  let isValid = true;
  const newErrors = {};
  if (!input.Name) {
    newErrors.Name = "Please enter your name";
    isValid = false;
  }
  if (!input.Mobile || !/^\d{10}$/.test(input.Mobile)) {
    newErrors.Mobile = "Please enter a valid 10-digit mobile number";
    isValid = false;
  }
  if (!input.Email || !/\S+@\S+\.\S+/.test(input.Email)) {
    newErrors.Email = "Please enter a valid email address";
    isValid = false;
  }
  return { isValid, newErrors };
};
