import React, { useEffect, useRef } from "react";
import { fadeInWhenVisible } from "../Utils/ServiceBlock";

const ServiceBlock = ({ service }) => {
  const textRef = useRef(null);

  useEffect(() => {
    let timeoutId;
    const observer = fadeInWhenVisible(timeoutId);
    if (textRef.current) {
      observer.observe(textRef.current);
    }
    // return () => {
    //   if (textRef.current) {
    //     observer.unobserve(textRef.current);
    //   }
    // };
  }, []);

  return (
    <div className="service-block">
      <img className="service-block-img" src={service.img} alt="" />
      <div className="service-block-title fade-in-text" ref={textRef}>
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </div>
    </div>
  );
};

export default ServiceBlock;
