import React, { useEffect, useState } from "react";
import Enquire from "./Enquire";
import Services from "./Services";
import Clients from "./Clients";
import Testimonials from "./Testimonials";
import Contacts from "./Contacts";
import Maps from "./Map";
import { scrollToTop } from "../Utils/scrolltoTop";

const Main = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    function handleScroll() {
      window.scrollY > 350 ? setShowButton(true) : setShowButton(false);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <main className="main">
      <div className="main-content">
        <Enquire />
        <Services />
        <Clients />
        <Testimonials />
        <Contacts />
        <Maps />
        <button
          onClick={scrollToTop}
          title="scroll-to-top"
          className="scroll-to-top-button"
          style={{ position: "sticky", display: showButton ? "block" : "none" }}
        >
          ↑
        </button>
      </div>
    </main>
  );
};

export default Main;
