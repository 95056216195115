import React from "react";

const Map = () => {
  const location = {
    latitude: 13.208455130482234,
    longitude: 77.62071302962595,
    zoom: 12,
  };

  const mapUrl = `https://maps.google.com/maps?q=${location.latitude},${location.longitude}&z=${location.zoom}&output=embed`;

  return (
    <div
      style={{
        minWidth: "40vh",
        maxWidth: "155vh",
        margin: "0 auto",
        border: "6px solid rgba(12, 33, 55, 1)",
      }}
    >
      <iframe
        title="Google Maps"
        width="100%"
        height="350"
        border="none"
        loading="lazy"
        allowFullScreen
        src={mapUrl}
      ></iframe>
    </div>
  );
};

export default Map;
