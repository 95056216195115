import React from "react";
import Navbar from "./Navbar";
import "../Styles/Footer.css";

const Footer = () => {
  const NavStyles = {
    nav: "footer-nav",
    ul: "footer-list-container",
    li: "footer-list",
  };

  return (
    <footer className="footer" style={{ position: "sticky" }}>
      <Navbar NavStyles={NavStyles} />
    </footer>
  );
};

export default Footer;
