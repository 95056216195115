import React from "react";
import "../Styles/Enquire.css";
import imageSrc from "../Assets/enquire.jpg";
import imageSrc1 from "../Assets/enquire.png";
import { scrollToTBottom } from "../Utils/scrolltoTop";

const Enquire = () => {
  return (
    <div className="enquire-content">
      <img src={imageSrc} alt="main" className="enquire-content-image" />
      <div className="enquire-img-container">
        <img src={imageSrc1} alt="main1" className="enquire-image" />
      </div>
      <div className="enquire-content-container">
        <h1>Professionals Handling Your Civil Contracts</h1>
        <button className="enquire-btn">
          <a className="enquire-link" href="#contacts">
            Enquire
          </a>
        </button>
      </div>
    </div>
  );
};

export default Enquire;
