import React, { useCallback, useEffect, useState } from "react";
import "../Styles/Header.css";
import Navbar from "./Navbar";
import { IoClose } from "react-icons/io5";
import { TiThMenu } from "react-icons/ti";
import img from "../Assets/enquire.png";

const Header = () => {
  const [activeNav, setActiveNav] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");

  const period = 100;
  const [delta, setDelta] = useState(100);

  const toRotate = [" Constructions and Interiors"];

  const intervalSetter = useCallback(() => {
    const currentText = toRotate[textIndex];
    setText((prevText) => {
      if (!isDeleting && prevText === currentText) {
        setIsDeleting(true);
        setDelta(period);
      }
      if (isDeleting && prevText === "") {
        setIsDeleting(false);
        setTextIndex((prevIndex) => (prevIndex + 1) % toRotate.length);
        setDelta(70);
      }
      return isDeleting
        ? prevText.substring(0, prevText.length - 1)
        : currentText.substring(0, prevText.length + 1);
    });
  }, [textIndex, isDeleting, toRotate, period]);
  useEffect(() => {
    const ticker = setInterval(() => intervalSetter(), delta);

    return () => clearInterval(ticker);
  }, [textIndex, isDeleting, delta]);

  useEffect(() => {
    const handleResize = () => {
      setActiveNav(window.innerWidth > 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const NavStyles = {
    nav: "header-nav",
    ul: "header-list-container",
    li: "header-list",
    a: "header-nav-link",
  };

  return (
    <header className="header">
      <div className="nav-btn-container">
        <button className="nav-btn" onClick={() => setActiveNav(!activeNav)}>
          {activeNav ? <IoClose /> : <TiThMenu />}
        </button>
      </div>

      <div className="header-title-container">
        <h3 className="header-title">CC {text}</h3>
      </div>
      {activeNav && <Navbar NavStyles={NavStyles} />}
    </header>
  );
};

export default Header;
