export const testimonials = [
  {
    description:
      "CC Constructions and Interiors exceeded our expectations, delivering results that speak for themselves.",
    name: "Ankush Singh",
  },
  {
    description:
      "CC Constructions and Interiors turned our dream home into a reality with exceptional building and developing services.",
    name: "Rakshit Shetty",
  },
  {
    description:
      "Their turnkey project work provided a seamless and stress-free experience.",
    name: "Girish Rawat",
  },
  {
    description:
      "Thanks to CC Constructions and Interiors, our interior works showcase elegance and functionality.",
    name: "Naveen Patil",
  },
  {
    description:
      "We entrusted CC Constructions and Interiors with our building renovation, and they transformed our space beyond belief.",
    name: "Madhu Sen Gupta",
  },
  {
    description:
      "Choosing CC Constructions and Interiors for our individual villa project was the best decision we made; they truly captured our vision.",
    name: "Pramod Wadekar",
  },
  {
    description:
      "Professionalism, expertise, and attention to detail define CC Constructions and Interiors; they are unmatched in the industry.",
    name: "Rashmi Pandey",
  },
  {
    description:
      "Our experience with CC Constructions and Interiors as construction contractors was nothing short of excellent.",
    name: "Ananth Sharma",
  },
  {
    description:
      "From concept to completion, CC Constructions and Interiors demonstrated unparalleled dedication to our project.",
    name: "Gangadhar Reddy",
  },
  {
    description:
      "With CC Constructions and Interiors, quality craftsmanship and timely delivery are guaranteed.",
    name: "Sunil Verma",
  },
  {
    description:
      "Their team's commitment to excellence sets CC Constructions and Interiors apart from the rest.",
    name: "Manjunath Pallad",
  },
  // {
  //   description: "We are thrilled with the outcome of our project, all thanks to CC Constructions and Interiors' meticulous approach.",
  //   name: "",
  // },
  // {
  //   description: "CC Constructions and Interiors' attention to detail ensured every aspect of our project was executed flawlessly.",
  //   name: "",
  // },
  // {
  //   description: "Choosing CC Constructions and Interiors for our construction needs was the best investment we made.",
  //   name: "",
  // },
  // {
  //   description: "Their professionalism and reliability make CC Constructions and Interiors our go-to choice for any future projects.",
  //   name: "",
  // }
];
